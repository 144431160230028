import { useParams } from "react-router-dom"
import BreadCrumb from "../../../Components/BreadCrumb"
import { Box, Container, Grid2 as Grid, Stack } from "@mui/material"
import SectionTitle from "../../../Components/SectionTitle"
import { useTranslation } from "react-i18next"
import { useList_Products_PublicQuery } from "../../../graphql/queries/products.generated"
import NotFoundData from "../../../Components/NotFoundData"
import { KitchenCard, KitchenCardLoading } from "../../../Components/KitchenCard"
import { getBackendUri } from "../../../Components/helperFunctions/getBackendUri"
import notRestaurant from "../../../assets/images/not-restaurant.svg";

const slugify = (text: string) => text.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9-\u0600-\u06FF]/g, "");

const Products = () => {
    const { kitchenId, categoryId } = useParams()
    const { t } = useTranslation()

    const { data, loading } = useList_Products_PublicQuery({
        variables: {
            input: {
                subCategoryId: parseInt(categoryId!)
            },
            first: 100,
            page: 1
        },
    });    

    const prevLink = [
        {
            name: "kitchens",
            link: `kitchens`
        },
        {
            name: data?.listProducts.data?.[0].category.name!,
            link: `kitchens/${data?.listProducts.data[0].category.id}/${data?.listProducts.data[0].category.name}`
        },
    ]

    return (
        <Box>
            <BreadCrumb prevLink={prevLink} pageLink={data?.listProducts.data[0].subCategory.name!} />
            <Container maxWidth="lg">
                <Stack my={2}>
                    <SectionTitle sectionTitle={t("products")} />
                    {data?.listProducts.data.length === 0 ? <NotFoundData message={t("noCategoriesFound")} />
                        : <Grid container spacing={3} justifyContent={"center"}>
                            {loading && [1, 2, 3, 4].map((e) =>
                                <Grid size={{ md: 3, xs: 6 }} key={e}>
                                    <KitchenCardLoading />
                                </Grid>
                            )}
                            {data?.listProducts.data.map((i) =>
                                <Grid size={{ md: 3, xs: 6 }} key={i.id}>
                                    <KitchenCard
                                        title={i.name}
                                        imageUrl={i.image?.path ? getBackendUri(i.image?.path) : notRestaurant}
                                        to={!i.active ? "" : `/products/${i.id}/${slugify(i.name)}`}
                                        soon={!i.active}
                                    />
                                </Grid>
                            )}
                        </Grid>}
                </Stack>
            </Container>
        </Box>

    )
}

export default Products