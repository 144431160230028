import { RouterProvider } from 'react-router-dom';
import router from './Routes/routerConfig';
import { useTranslation } from 'react-i18next';
import withRoot from './Components/HOC/MuiTheme';

function App() {

  const { i18n } = useTranslation();
  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true
      }}
    />
  );
}

export default withRoot(App);
