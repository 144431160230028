import { Grid2 as Grid, Paper, Stack, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material/styles";
import { SettingsInput } from "../../../graphql/types"
import { LoadingButton } from "@mui/lab"
import Loading from "../../../Components/Loading"
import { useSettingsQuery } from "../../../graphql/queries/settings.generated"
import ControlMUITextField from "../../../Components/MUI/ControlMUItextField";
import { useUpdate_SettingsMutation } from "../../../graphql/mutations/settings.generated";
import { toast } from "sonner";
import moment from "moment";
import MuiTimePicker from "../../../Components/MUI/MuiTimePicker";


const PREFIX = "products-created";
const classes = {
    editor: `${PREFIX}-editor`,
    editorError: `${PREFIX}-editorError`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.editor}`]: {
        ".ql-toolbar": {
            background: "#fff",
            direction: theme.direction,
        },
        ".ql-editor": {
            minHeight: "250px",
            direction: theme.direction,
        },
    },
    [`& .${classes.editorError}`]: {
        border: "1px solid red",
    },
}));

type SetValueKeys =
    | "tax"
    | "deliveryFess";

const getTime = (time: string) => moment(time).format('HH:mm');
const stringFormatToDate = (date: string) => moment(date, "HH:mm").toDate();

const Settings = () => {
    const { t } = useTranslation()

    const { control, handleSubmit, setValue } = useForm<SettingsInput>()

    const { loading: settingsLoading } = useSettingsQuery({
        fetchPolicy: "no-cache",
        onCompleted(data) {
            const settings = data.settings;
            const settingsParams: SetValueKeys[] = ["tax", "deliveryFess"];

            settingsParams.forEach((i) => {
                if (settings[i]) {
                    setValue(i, settings[i]);
                }
            });
            setValue("lastOrder", stringFormatToDate(data.settings.lastOrder))
        },
    });

    const [updateSettingsMutation, { loading }] = useUpdate_SettingsMutation();

    const onSubmit = (data: SettingsInput) => {
        updateSettingsMutation({
            variables: {
                input: {
                    deliveryFess: Number(data.deliveryFess),
                    tax: Number(data.tax),
                    lastOrder: getTime(data.lastOrder)
                }
            }
        }).then((data) => {
            console.log(data);
        }).catch((err) => {
            toast.error(err)
        })
    }

    return settingsLoading ? <Loading /> : (
        <Root spacing={2}>
            <Typography fontSize={20}>{t("settings")}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack component={Paper} p={2} spacing={2}>
                    <Grid container spacing={2} m={0}>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("deliveryFess")}
                                control={control}
                                name="deliveryFess"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <ControlMUITextField
                                label={t("tax")}
                                control={control}
                                name="tax"
                                rules={{
                                    required: t("fieldIsRequired"),
                                }}
                                type="number"
                            />
                        </Grid>
                        <Grid size={{ sm: 6, xs: 12 }}>
                            <MuiTimePicker
                                label={t("lastOrder")}
                                control={control}
                                name="lastOrder"
                            />
                        </Grid>

                    </Grid>
                    <Stack alignItems={"flex-end"}>
                        <LoadingButton loading={loading} variant="contained" color="success" type="submit">
                            {t("save")}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Root>
    )
}

export default Settings