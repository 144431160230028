import { Stack, Typography } from '@mui/material'
import Image from '../assets/images/noFood.webp'

const NotFoundData = ({ message }: { message: string }) => {
    return (
        <Stack height={300} justifyContent={"center"} alignItems={"center"}>
            <Typography fontSize={20}>
                {message}
            </Typography>
        </Stack>
    )
}

export default NotFoundData