import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { styled } from "@mui/material/styles";
import { Fragment, memo } from "react";
import { Collapse } from "@mui/material";

import {
    DashboardOutlined,
    PeopleOutlineOutlined,
    Inventory2Outlined,
    InventoryOutlined,
    Settings
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const PREFIX = "NavDrawer";

const classes = {
    link: `${PREFIX}-link`,
    listIcon: `${PREFIX}-listIcon`,
    listItem: `${PREFIX}-listItem`,
    list: `${PREFIX}-list`,
    ListItemText: `${PREFIX}-ListItemText`,
    ListItemTextChild: `${PREFIX}-ListItemTextChild`,
};

const drawerWidth = 240;

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Drawer)(({ theme }) => ({
    [`& .${classes.link}`]: {
        textDecoration: "none",
    },
    [`& .${classes.listIcon}`]: {
        minWidth: "25px",
    },
    [`& .${classes.listItem}`]: {
        padding: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        [`&:hover`]: {
            borderRadius: theme.spacing(1)
        },
    },

    [`& .${classes.ListItemText}`]: {
        margin: 0,
        textTransform: "capitalize",
        color: "black"
    },

    [`& .${classes.ListItemTextChild}`]: {
        color: "grey"
    },

    [`& .${classes.list}`]: {
        padding: theme.spacing(2)
    },

}));

interface propsInput {
    open: boolean,
    DrawerHeader: any,
}

interface LinksListChildren {
    pathname: string,
    icon: any,
    primary: string,
}
interface LinksList {
    pathname: string,
    icon: any,
    primary: string,
    regex: RegExp,
    sectionName?: string,
    children?: LinksListChildren[],
}

const NavDrawer = (props: propsInput) => {
    const { open, DrawerHeader } = props;

    const linksList: LinksList[] = [
        {
            pathname: "/dashboard",
            icon: DashboardOutlined,
            primary: "dashboard",
            regex: /dashboard/,
        },
        {
            pathname: "/dashboard/products",
            sectionName: "products",
            icon: Inventory2Outlined,
            primary: "products",
            regex: /categories|products|kitchens/,
            children: [
                {
                    pathname: "/dashboard/kitchens",
                    primary: "kitchens",
                    icon: "",
                },
                {
                    pathname: "/dashboard/categories",
                    primary: "categories",
                    icon: "",
                },
            ],
        },
        {
            regex: /orders/,
            pathname: "/dashboard/orders",
            sectionName: "orders",
            icon: InventoryOutlined,
            primary: "orders",
        },
        {
            regex: /branches/,
            pathname: "/dashboard/branches",
            sectionName: "branches",
            icon: InventoryOutlined,
            primary: "branches",
        },
        {
            regex: /users/,
            pathname: "/dashboard/users",
            sectionName: "users",
            icon: PeopleOutlineOutlined,
            primary: "users",
        },
        {
            regex: /appointments/,
            pathname: "/dashboard/appointments",
            sectionName: "appointments",
            icon: PeopleOutlineOutlined,
            primary: "appointments",
        },
        {
            regex: /settings/,
            pathname: "/dashboard/settings",
            sectionName: "settings",
            icon: Settings,
            primary: "settings",
        },
    ];

    const { t } = useTranslation()
    const { pathname } = useLocation()

    return (
        <Root
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader />
            <Divider />
            <List className={classes.list}>
                {linksList.map((link, index) => {
                    if (!link.children) {
                        return <Link to={link.pathname} key={index} className={classes.link}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        {<link.icon fontSize={"small"} />}
                                    </ListItemIcon>
                                    <ListItemText
                                        className={classes.ListItemText}
                                        disableTypography={true}
                                        primary={t(link.primary)}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    } else {
                        return <Fragment key={index}>
                            <Link to={link.pathname} className={classes.link}>
                                <ListItemButton
                                    className={classes.listItem}>
                                    <ListItemIcon className={classes.listIcon}>
                                        {<link.icon fontSize={"small"} />}
                                    </ListItemIcon>
                                    <ListItemText
                                        className={classes.ListItemText}
                                        disableTypography={true}
                                        primary={t(link.primary)}
                                    />
                                </ListItemButton>
                            </Link>
                            <Collapse
                                key={index}
                                in={link.regex.test(pathname) ?? false}
                                timeout="auto"
                                unmountOnExit
                            >
                                {link?.children?.map((child, i) => {
                                    return (
                                        <Link to={child.pathname} key={i} className={classes.link}>
                                            <ListItem
                                                className={classes.listItem}
                                            >
                                                <ListItemIcon className={classes.listIcon}>
                                                </ListItemIcon>
                                                <ListItemText
                                                    className={`${classes.ListItemText} ${classes.ListItemTextChild}`}
                                                    disableTypography={true}
                                                    primary={t(child.primary)}
                                                />
                                            </ListItem>
                                        </Link>

                                    );
                                })}
                            </Collapse>
                        </Fragment>
                    }
                })}
            </List>
        </Root>
    );
};

export default memo(NavDrawer);
