import { Button, Container, Grid2 as Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import LabelValue from "../../Components/LabelValue";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Store/store";
import { Edit } from "@mui/icons-material";

const PREFIX = "profile-info";

const classes = {
  background: `${PREFIX}-background`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
  tabs: `${PREFIX}-tabs`,
};

const Root = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),
  [`& .${classes.background}`]: {
    background: theme.palette.primary.main,
    height: "120px"
  },
}));

const Profile = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth);  

  return (
    <Root>
      <Container maxWidth={"md"}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"}>
            <Button
              size="small"
              variant="outlined"
              endIcon={<Edit fontSize="small" />}
            >
              {t("update")}
            </Button>
          </Grid>
          <Grid size={{ sm: 6, xs: 12 }}>
            <LabelValue label={t("name")} value={user?.name!} icon="person" />
          </Grid>
          <Grid size={{ sm: 6, xs: 12 }}>
            <LabelValue label={t("email")} value={user?.email!} icon="email" />
          </Grid>
          <Grid size={{ sm: 6, xs: 12 }}>
            <LabelValue label={t("birthdate")} value={user?.birthdate!} icon="today" />
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}

export default Profile

