import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define a type for the slice state
interface CartState {
    value: number; // Represents total count of items in the cart
    stateCartDrawer: boolean; // Tracks the visibility of the cart drawer
}

// Define the initial state using that type
const initialState: CartState = {
    value: 0,
    stateCartDrawer: false,
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // Update cart item count
        changeCartCount: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
        },
        // Update drawer state
        changeDialogState: (state, action: PayloadAction<boolean>) => {
            state.stateCartDrawer = action.payload;
        },
        // Set default cart items
        changeCart: (state, action: PayloadAction<{ count: number, stateCartDrawer: boolean }>) => {
            state.value = action.payload.count;
            state.stateCartDrawer = action.payload.stateCartDrawer
        },
        resetCartData: (state) => {
            state.value = 0;
        }
    },
});

export const {
    changeCartCount,
    changeDialogState,
    changeCart,
    resetCartData
} = cartSlice.actions;

// Selectors
export const selectCart = (state: RootState) => state.cart

export default cartSlice.reducer;
