import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Update_SettingsMutationVariables = Types.Exact<{
  input: Types.SettingsInput;
}>;


export type Update_SettingsMutation = { __typename?: 'Mutation', updateSettings: { __typename?: 'Settings', lastOrder?: any | null, tax?: number | null, deliveryFess?: number | null } };


export const Update_SettingsDocument = gql`
    mutation UPDATE_SETTINGS($input: SettingsInput!) {
  updateSettings(input: $input) {
    lastOrder
    tax
    deliveryFess
  }
}
    `;
export type Update_SettingsMutationFn = Apollo.MutationFunction<Update_SettingsMutation, Update_SettingsMutationVariables>;

/**
 * __useUpdate_SettingsMutation__
 *
 * To run a mutation, you first call `useUpdate_SettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_SettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdate_SettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_SettingsMutation(baseOptions?: Apollo.MutationHookOptions<Update_SettingsMutation, Update_SettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_SettingsMutation, Update_SettingsMutationVariables>(Update_SettingsDocument, options);
      }
export type Update_SettingsMutationHookResult = ReturnType<typeof useUpdate_SettingsMutation>;
export type Update_SettingsMutationResult = Apollo.MutationResult<Update_SettingsMutation>;
export type Update_SettingsMutationOptions = Apollo.BaseMutationOptions<Update_SettingsMutation, Update_SettingsMutationVariables>;