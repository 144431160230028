import { Avatar, Button, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material'
import { CircleOutlined, Delete, Edit, Place, TaskAlt } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AddressesTypes, defaultAddress, deleteAddress } from '../../../Store/slices/auth'
import { useDelete_AddressMutation, useSet_Address_DefaultMutation } from '../../../graphql/queries/user.generated'
import CustomDialog from '../../../Components/MUI/CustomDialog'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useAppDispatch } from '../../../Store/store'

const AddressPaper = ({
    address,
    handleOpenWithData
}: {
    address: AddressesTypes
    handleOpenWithData: (address: AddressesTypes) => void
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [deleteAddressMutation, { loading: deleteAddressLoading }] = useDelete_AddressMutation();
    const [setAddressDefaultMutation, { loading: setAddressDefaultLoading }] = useSet_Address_DefaultMutation();

    const dispatch = useAppDispatch()

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const deleteFunction = () => {
        deleteAddressMutation({
            variables: {
                id: address.id
            }
        }).then(() => {
            dispatch(deleteAddress(address.id))
        })
    }

    const defaultFunction = () => {
        setAddressDefaultMutation({
            variables: {
                id: address.id
            }
        }).then(() => {
            dispatch(defaultAddress(address.id))
        })
    }

    return (
        <Stack component={Paper} direction={"row"} alignItems={"center"} gap={2} p={2}>
            {open &&
                <CustomDialog
                    open={open}
                    handleClose={handleClose}
                    title={t("deleteAddress")}
                    content={t("areYouSure")}
                    buttonAction={
                        <LoadingButton
                            color='error'
                            loading={deleteAddressLoading}
                            onClick={deleteFunction}
                            variant='contained'
                        >
                            {t("submit")}
                        </LoadingButton>
                    }
                />
            }

            <Stack width={"100%"} spacing={1}>
                <Stack direction={"row"} spacing={0.5} justifyContent={"space-between"}>
                    <Avatar sx={{ width: "30px", height: "30px", bgcolor: "primary.main" }}>
                        <Place fontSize='small' />
                    </Avatar>
                    <Stack direction={"row"} spacing={0.5} >
                        <Button
                            size="small"
                            color='success'
                            endIcon={
                                address.default
                                    ? <TaskAlt />
                                    : (setAddressDefaultLoading ?
                                        <CircularProgress size={13} /> :
                                        <CircleOutlined fontSize='small' />)
                            }
                            onClick={defaultFunction}
                        >
                            {t("default")}
                        </Button>
                        <IconButton
                            color='info'
                            size="small"
                            sx={{ bgcolor: "InfoBackground" }}
                            onClick={() => handleOpenWithData(address)}
                        >
                            <Edit />
                        </IconButton>
                        {!address.default &&
                            <IconButton
                                size="small"
                                onClick={handleOpen}
                                color='error'
                            >
                                <Delete />
                            </IconButton>
                        }
                    </Stack>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <Typography>{t("phone")} 1: </Typography>
                    <Typography>{address.phone ?? t("placeholder")}</Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <Typography>{t("phone")} 2: </Typography>
                    <Typography>{address.mobile ?? t("placeholder")}</Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <Typography>{t("address")}: </Typography>
                    <Typography>{address.address ?? t("placeholder")}</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default AddressPaper