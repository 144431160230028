import { Button, Container, Grid2 as Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Store/store";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import AddressDialog from "./Components/AddressDialog";
import AddressPaper from "./Components/AddressPaper";
import { AddressesTypes } from "../../Store/slices/auth";

const PREFIX = "profile-address";

const classes = {
  background: `${PREFIX}-background`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
  tabs: `${PREFIX}-tabs`,
};

const Root = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),
  [`& .${classes.background}`]: {
    background: theme.palette.primary.main,
    height: "120px"
  },
}));

// export type addressDataTypes = {
//   address: string
//   date: string
//   default: string
//   id: number
//   mobile?: string
//   phone?: string
// }

const Addresses = () => {
  const { t } = useTranslation()
  const { addresses } = useAppSelector((state) => state.auth);
  const [open, setOpen] = useState(false)
  const [addressData, setAddressData] = useState<AddressesTypes | undefined>()

  const handleClose = () => {
    setAddressData(undefined)
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleOpenWithData = (address: AddressesTypes) => {
    setAddressData(address)
    setOpen(true)
  }

  return (
    <Root>
      {open && <AddressDialog open={open} handleClose={handleClose} addressData={addressData} />}
      <Container maxWidth={"md"}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"}>
            <Button
              size="small"
              variant="outlined"
              endIcon={<Add fontSize="small" />}
              onClick={handleOpen}
            >
              {t("addAddress")}
            </Button>
          </Grid>
          {addresses.map(address =>
            <Grid size={{ sm: 6, xs: 12 }} key={address.id}>
              <AddressPaper address={address} handleOpenWithData={handleOpenWithData} />
            </Grid>
          )}
          {addresses.length === 0 &&
            <Grid size={{ sm: 6, xs: 12 }} >
              <Stack component={Paper} minHeight={80} justifyContent={"center"} alignItems={"center"}>
                <Typography>{t("noAddresses")}</Typography>
              </Stack>
            </Grid>
          }
        </Grid>
      </Container>
    </Root>
  )
}

export default Addresses

