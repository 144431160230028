import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { useState } from "react";

// Fix for default Marker icon in Leaflet
import iconUrl from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

// Set the default icon
let DefaultIcon = L.icon({
  iconUrl,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

const LocationPicker = ({
  location,
  locationPickedFun,
  height,
  view = false,
}: {
  location?: [number, number]; // Explicitly define type as [lat, lng]
  locationPickedFun?: (location: [number, number]) => void; // Callback with [lat, lng]
  height?: string;
  view?: boolean;
}) => {
  // If no initial location is provided, use a default one
  const [selectedLocation, setSelectedLocation] = useState<[number, number]>(
    location ?? [51.505, -0.09] // Default coordinates if `location` is undefined
  );

  const LocationSelector = () => {
    useMapEvents({
      click(e) {
        // Disable interaction if view is true
        if (view) return;

        const latlng = e.latlng;
        if (locationPickedFun) {
          locationPickedFun([latlng.lat, latlng.lng]);
        }
        setSelectedLocation([latlng.lat, latlng.lng]);
      },
    });

    return selectedLocation ? (
      <Marker position={selectedLocation} />
    ) : null;
  };

  return (
    <div>
      <MapContainer
        center={selectedLocation} // Use selectedLocation for the map center
        zoom={13}
        style={{ height: height ?? "500px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <LocationSelector />
      </MapContainer>
    </div>
  );
};

export default LocationPicker;
