import * as gqlb from "gql-query-builder";

export const ME_QUERY = gqlb.query([{
    operation: "me",
    fields: [
        "id",
        "name",
        "email",
        "birthdate",
        "isAdmin",
        // cart {
        //     id
        //     price
        //     items {
        //       id
        //       product{
        //         name
        //         price
        //         weight
        //         id
        //         image{
        //           path
        //         }
        //       }
        //       quantity
        //       price
        //       options{
        //         id
        //         value
        //       }
        //       addons{
        //         id
        //         addon{
        //           price
        //         }
        //       }
        //     }
        //   },
        {
            cart: [
                "id",
                {
                    items: ["id"]
                }
            ]
        }
    ],
    variables: {}
}]);

export const CATEGORIES = gqlb.query([{
    operation: "listCategories",
    fields: [
        { data: ["id"] }
    ],
    variables: {
        input: {
            type: "ListCategoriesFilterInput",
        },
        page: {
            type: "Int",
        },
        first: {
            type: "Int",
        },
    }
}]);