import { Skeleton, TableBody, TableRow } from "@mui/material";
import { FixedTableCell } from "./FixedTableCell";

interface TableBodyWithLoadProps {
  loading: boolean;
  tableCellHeaderLength: number;
  children: React.ReactNode; // Ensure children can be rendered as valid JSX
}

const TableBodyWithLoad = (props: TableBodyWithLoadProps): React.ReactElement | null => {
  const { loading, tableCellHeaderLength, children } = props;

  const loadingArray = Array.from({ length: 11 }, (_, index) => index + 1);
  const FixedTableCellArray = Array.from({ length: tableCellHeaderLength }, (_, index) => index + 1);

  if (!loading) {
    // Ensure children is wrapped in a valid JSX element
    return <>{children}</>;
  }

  return (
    <TableBody>
      {loadingArray.map((row) => (
        <TableRow hover tabIndex={-1} key={row}>
          {FixedTableCellArray.map((e) => (
            <FixedTableCell key={`${row}-${e}`}>
              <Skeleton animation="wave" width={50} height={50} />
            </FixedTableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableBodyWithLoad;
