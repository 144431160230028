import { createBrowserRouter, Outlet } from 'react-router-dom';

// Import Public Pages
import NotFound from '../Components/NotFound';
import Login from '../Pages/auth/Login';
import Register from '../Pages/auth/Register';
import Home from '../Pages/public/Home';
import HomeLayout from '../Components/HomeLayout';
import ApolloProviderComponent from '../Providers/ApolloProviderComponent';
import { ROLE } from '../types';
import ProfileLayout from '../Pages/customer/ProfileLayout';
import Profile from '../Pages/customer/Profile';
import Orders from '../Pages/customer/Orders';
import Addresses from '../Pages/customer/Addresses';
import ProtectedRoute from './ProtectedRoute';
import NotAnAdmin from './NotAnAdmin';
import Kitchens from '../Pages/public/Kitches';
import Cart from '../Pages/public/Cart/Cart';
import Dashboard from '../Pages/admin/Dashboard/Dashboard';
import DashboardLayout from '../Pages/admin/Layouts/DashboardLayout';
import ProductsList from '../Pages/admin/Products/ProductsList';
import ProductCreate from '../Pages/admin/Products/ProductCreate';
import KitchensList from '../Pages/admin/Kitchens/KitchensList';
import CategoriesList from '../Pages/admin/Categories/CategoriesList';
import Categories from '../Pages/public/Categories/Categories';
import Products from '../Pages/public/Products/Products';
import ProductView from '../Pages/public/Products/ProductView';
import BranchesView from '../Pages/admin/Branches/BranchesView';
import { BranchesCreate } from '../Pages/admin/Branches/BranchesCreate';
import BranchesList from '../Pages/admin/Branches/BranchesList';
import ListBranches from '../Pages/public/Branches/ListBranches';
import ViewBranch from '../Pages/public/Branches/ViewBranch';
import UsersList from '../Pages/admin/users/UsersList';
import Checkout from '../Pages/customer/Checkout';
import CartChecker from './CartChecker';
import AppointmentsList from '../Pages/admin/Appointments/AppointmentsList';
import Settings from '../Pages/admin/Settings/Settings';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    element: <ApolloProviderComponent >
      <Outlet />
    </ApolloProviderComponent>,
    children: [
      {
        path: '/',
        element: <HomeLayout />,
        children: [
          { path: '/', element: <Home /> },
          // {
          //   path: '/kitchens',
          //   element: <Kitchens />
          // },
          // {
          //   path: '/kitchens/:kitchenName/:kitchenId',
          //   element: <Categories />
          // },
          // {
          //   path: '/kitchens/:kitchenName/:kitchenId/:categoryName/:categoryId',
          //   element: <Products />
          // },
          // {
          //   path: '/kitchens/:kitchenName/:kitchenId/:categoryName/:categoryId/:productName/:productId',
          //   element: <ProductView />
          // },
          {
            path: '/kitchens',
            children: [
              { index: true, element: <Kitchens /> },
              {
                path: ':kitchenId/:kitchenName',
                element: <Categories />
              },
            ],
          },
          {
            path: '/categories/:categoryId/:categoryName',
            element: <Products />
          },
          {
            path: '/products/:productId/:productName',
            element: <ProductView />
          },
          // { path: '/kitchens/:id', element: <KitchenMenu /> },
          // { path: '/kitchens', element: <Kitchens /> },
          { path: '/cart', element: <NotAnAdmin><Cart /></NotAnAdmin> },
          {
            path: '/checkout',
            element: <ProtectedRoute checkRole={ROLE.CUSTOMER} homeLayout>
              <CartChecker>
                <Checkout />
              </CartChecker>
            </ProtectedRoute>
          },
          {
            path: '/profile',
            element:
              <ProtectedRoute checkRole={ROLE.CUSTOMER} homeLayout>
                <ProfileLayout />
              </ProtectedRoute>
            ,
            children: [
              { index: true, element: <Profile /> }, // Default child route for /profile
              { path: 'orders', element: <Orders /> }, // Example additional child route
              { path: 'addresses', element: <Addresses /> }, // Example additional child route
            ],
          },
          {
            path: '/branches',
            children: [
              { index: true, element: <ListBranches /> }, // Default child route for /profile
              { path: ':id/:branchName', element: <ViewBranch /> }, // Example additional child route
            ],
          },
          {
            path: '/login',
            element:
              <ProtectedRoute homeLayout>
                <Login />
              </ProtectedRoute>,
          },
          {
            path: '/register',
            element:
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>,
          },
        ]
      },
      {
        path: '/dashboard',
        element: <ProtectedRoute checkRole={ROLE.ADMIN} homeLayout><DashboardLayout /></ProtectedRoute>,
        errorElement: <div>sdf</div>,
        children: [
          { index: true, element: <Dashboard /> },
          {
            path: 'products',
            children: [
              { index: true, element: <ProductsList /> },
              { path: 'create', element: <ProductCreate /> },
              { path: ':id', element: <ProductCreate /> },
            ],
          },
          {
            path: 'branches',
            children: [
              { index: true, element: <BranchesList /> },
              { path: 'create', element: <BranchesCreate /> },
              {
                path: ':id',
                children: [
                  { index: true, element: <BranchesView /> },
                  { path: 'edit', element: <BranchesCreate /> }
                ]
              },
            ],
          },
          {
            path: 'users',
            children: [
              { index: true, element: <UsersList /> },
              // { path: 'create', element: <BranchesCreate /> },
              // {
              //   path: ':id',
              //   children: [
              //     { index: true, element: <BranchesView /> },
              //     { path: 'edit', element: <BranchesCreate /> }
              //   ]
              // },
            ],
          },
          {
            path: 'kitchens',
            element: <KitchensList />
          },
          {
            path: 'categories',
            element: <CategoriesList />
          },
          {
            path: 'appointments',
            element: <AppointmentsList />
          },
          {
            path: 'settings',
            element: <Settings />
          },
        ],
      },
    ],
  },

], {
  future: {
    v7_partialHydration: true, // Enable the fetcher persist behavior
    v7_relativeSplatPath: true, // Enable the fetcher persist behavior
    v7_skipActionErrorRevalidation: true, // Enable the fetcher persist behavior
    v7_normalizeFormMethod: true, // Enable the fetcher persist behavior
    v7_fetcherPersist: true, // Enable the fetcher persist behavior
  },
});

export default router;