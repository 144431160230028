import { Box } from "@mui/material"
import Kitchens from "../../Sections/Kitchens"
import BreadCrumb from "../../Components/BreadCrumb"

const KitchenMenu = () => {
  return (
    <Box>
      <BreadCrumb pageLink="kitchens" />
      <Kitchens />
    </Box>
  )
}

export default KitchenMenu