import { Stack, Typography } from '@mui/material'
import { getBackendUri } from '../helperFunctions/getBackendUri'
import { formatted } from '../helperFunctions/function'
import notRestaurant from "../../assets/images/not-restaurant.svg";
import DeleteButton from '../../Pages/public/Cart/Component/DeleteButton'
import { useTranslation } from 'react-i18next';

type itemTypes = {
    id: number
    name: string
    price: number
    totalPrice: number
    quantity: number
    image?: string
}

const CartItem = ({
    item,
    refetch
}: {
    item: itemTypes
    refetch: () => void
}) => {
    const { t } = useTranslation()
    return (
        <Stack direction={"row"} spacing={1} useFlexGap>
            <Stack width={80} height={80}>
                <img
                    src={item.image ? getBackendUri(item.image) : notRestaurant}
                    alt='sdf'
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "fill" }}
                />
            </Stack>

            <Stack spacing={0.5}>
                <Typography>{item.name}</Typography>
                <Typography>{t("totalPrice")}: {formatted(item.price)}</Typography>
                <Typography>{t("quantity")}: {item.quantity}</Typography>
            </Stack>
            <Stack alignItems={"flex-start"}>
                <DeleteButton id={item.id} refetch={refetch} />
            </Stack>
        </Stack>
    )
}

export default CartItem