import { Box } from '@mui/material'
import BreadCrumb from '../../../Components/BreadCrumb'
import Branches from '../../../Sections/Branches'

const ListBranches = () => {
    return <Box>
        <BreadCrumb pageLink="kitchens" />
        <Branches />
    </Box>

}

export default ListBranches