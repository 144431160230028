import { Breadcrumbs, Skeleton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PREFIX = "BreadCrumb";
const classes = {
    activeLink: `${PREFIX}-activeLink`,
    breadcrumbs: `${PREFIX}-breadcrumbs`,
};

const Root = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.paper,
    // paddingTop: "px",
    width: "100%",
    height: 150,
    [`& .${classes.activeLink}`]: {
        color: theme.palette.text.primary,
        fontSize: "1.1rem",
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "capitalize",
        opacity: 0.7,
        [`&:hover`]: {
            color: theme.palette.primary.main,
            opacity: 1,
            textDecoration: "underline",
        },
    },
    [`& .${classes.breadcrumbs} .MuiBreadcrumbs-ol`]: {
        justifyContent: "center",
        alignItems: "center",

    },
}));

interface inputProps {
    pageLink: string;
    prevLink?: {
        name: string
        link: string
    }[];
}

const BreadCrumb = (props: inputProps) => {
    const { pageLink, prevLink } = props;
    const { t } = useTranslation();
    return (
        <Root justifyContent={"center"} alignItems={"center"}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Link
                    to={"/"}
                    className={classes.activeLink}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t("home")}
                </Link>
                {prevLink && prevLink.map((link, i) =>
                    <Link to={`/${link.link}`} className={classes.activeLink} key={i}>
                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {link.name ? t(link.name) : <Skeleton animation="wave" width={"40px"} />}
                    </Link>
                )}
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    fontSize={"1.1rem"}
                    color="primary.main"
                    textTransform="capitalize"
                >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {pageLink ? t(pageLink) : <Skeleton animation="wave" width={"40px"} />}
                </Typography>
            </Breadcrumbs>
        </Root>
    )
}

export default BreadCrumb
