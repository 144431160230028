import { useAppDispatch } from '../../../Store/store';
import { useNavigate } from 'react-router-dom';
import { changeAuthData } from '../../../Store/slices/auth';
import { changeCartCount } from '../../../Store/slices/cart';
import { ROLE } from '../../../types';

export interface DataTypes {
    token: string,
    user: {
        isAdmin: boolean
        birthdate: string
        email: string
        name: string
        addresses?: {
            id: number
            address: string
            default: boolean
            phone: string
            mobile: string
            date: string
        }
        cartCount: number
    }
}

const useAfterLogin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const callFunction = (data: DataTypes) => {
        localStorage.setItem("token", data.token!)
        const isAdmin = data.user.isAdmin

        dispatch(changeAuthData({
            isLoggedIn: true,
            role: isAdmin ? ROLE.ADMIN : ROLE.CUSTOMER,
            ...(!isAdmin && {
                user: {
                    birthdate: data.user.birthdate,
                    email: data.user.email!,
                    name: data.user.name!
                },
                addresses: data.user.addresses as any
            }),

        }))

        !isAdmin && dispatch(changeCartCount(data.user.cartCount))

        if (isAdmin) {
            navigate("/dashboard");
        } else {
            navigate("/");
        }
    }
    return { callFunction }
}

export default useAfterLogin