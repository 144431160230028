import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next';
import { useDeleteCartItemMutation } from '../../../../graphql/mutations/user.generated';
import { Delete } from '@mui/icons-material';
import CustomDialog from '../../../../Components/MUI/CustomDialog';
import { useState } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../Store/store';
import { changeCartCount } from '../../../../Store/slices/cart';

const DeleteButton = ({ id, refetch }: { id: number, refetch: () => void }) => {
    const { t } = useTranslation()
    const [deleteCartItemMutation, { loading }] = useDeleteCartItemMutation();
    const { value } = useAppSelector((s) => s.cart)
    const dispatch = useAppDispatch()

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }

    const deleteFun = () => {
        deleteCartItemMutation({
            variables: {
                id: id
            }
        }).then(() => {
            dispatch(changeCartCount(value - 1))
            refetch()
        })
    }

    return (
        <>
            <CustomDialog
                open={open}
                handleClose={handleClose}
                title={t("deleteProduct")}
                content={t("areYouSure")}
                buttonAction={
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        color='error'
                        size='small'
                        onClick={deleteFun}
                    >
                        {t("delete")}
                    </LoadingButton>
                }
            />
            <Button
                variant="outlined"
                color='error'
                startIcon={<Delete />}
                size='small'
                onClick={handleOpen}
            >
                {t("delete")}
            </Button>
        </>
    )
}

export default DeleteButton