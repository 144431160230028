import React from 'react'
import { useParams } from 'react-router-dom';
import { useBranchQuery } from '../../../graphql/queries/branches.generated';
import { styled } from "@mui/material/styles";
import { Grid2 as Grid, Paper, Stack } from '@mui/material';
import TablesTable from './Component/TablesTable';
import Loading from '../../../Components/Loading';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import LabelValue from '../../../Components/LabelValue';
import ButtonLink from '../../../Components/ButtonLink';

// const PREFIX = "products-created";
// const classes = {
//     editor: `${PREFIX}-editor`,
//     editorError: `${PREFIX}-editorError`,
// };

const Root = styled(Stack)(({ theme }) => ({

}));

const BranchesView = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { data, loading } = useBranchQuery({
    fetchPolicy: "no-cache",
    skip: !parseInt(id!),
    variables: {
      id: parseInt(id!)
    }
  });

  return (
    <Root spacing={2}>
      <Stack component={Paper} p={2}>
        {loading ? <Loading height="100px" /> :
          <Grid container spacing={3}>
            <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"}>
              <ButtonLink
                size="small"
                variant="outlined"
                to={"edit"}
                text={t("update")}
                endIcon={<Edit fontSize="small" />}
              />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <LabelValue label={t("name")} value={data?.branch.name!} />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <LabelValue label={t("startTime")} value={data?.branch?.startTime!} />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <LabelValue label={t("endTime")} value={data?.branch.endTime!} />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <LabelValue label={t("telephone")} value={data?.branch.telephone!} />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <LabelValue label={t("whatsapp")} value={data?.branch.whatsapp!} />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <LabelValue label={t("createdAt")} value={data?.branch.date!} />
            </Grid>
          </Grid>
        }
      </Stack>
      <TablesTable
        branchId={Number(id)}
      />
    </Root>
  )
}

export default BranchesView