import CustomDialog from '../../../../Components/MUI/CustomDialog'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import ControlMUITextField from '../../../../Components/MUI/ControlMUItextField'
import { useForm } from 'react-hook-form'
import { useSave_TableMutation } from '../../../../graphql/mutations/branches.generated'
import { TableInput } from '../../../../graphql/types'
import { LoadingButton } from '@mui/lab'
import { Action } from '../BranchesCreate'
import { Dispatch, useEffect } from 'react'

const TableCreate = ({
    open,
    handleClose,
    branchId,
    callRefetchFunction,
    updateData
}: {
    open: boolean
    handleClose: () => void
    branchId: number
    callRefetchFunction: () => void
    updateData: any
}) => {
    const { t } = useTranslation()
    const { handleSubmit, control, reset, setValue } = useForm<TableInput>()

    const [saveTableMutation, { loading }] = useSave_TableMutation();

    console.log(updateData);
    
    useEffect(() => {
        if (updateData) {
            setValue("name", updateData.name);
            setValue("chairsNumber", updateData.chairsNumber);
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data: TableInput) => {
        saveTableMutation({
            variables: {
                input: {
                    name: data.name,
                    chairsNumber: Number(data.chairsNumber),
                    ...(updateData && { id: updateData.id }),
                    branchId: branchId,
                }
            }
        }).then((data) => {
            handleClose()
            callRefetchFunction()
        }).catch(({ graphQLErrors }) => {
            console.log(graphQLErrors);
        })
    }

    const handleCloseDialog = () => {
        reset()
        handleClose()
    }

    return (
        <CustomDialog
            open={open}
            handleClose={handleCloseDialog}
            title={t("new")}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true
            }}
            content={
                <Stack spacing={2}>
                    <Stack>
                        <ControlMUITextField
                            label={t("name")}
                            control={control}
                            name="name"
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                        />
                    </Stack>
                    <Stack>
                        <ControlMUITextField
                            label={t("chairsNumber")}
                            control={control}
                            name="chairsNumber"
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                            type="number"
                        />
                    </Stack>
                </Stack>
            }
            buttonAction={
                <LoadingButton loading={loading} variant='contained' type='submit'>{t("submit")}</LoadingButton>
            }
        />
    )
}

export default TableCreate