import { ApolloClient, ApolloLink, ApolloProvider, gql, InMemoryCache } from '@apollo/client';
import React, { memo, useEffect, useState } from 'react'
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { CATEGORIES } from './apolloQueries';
import { useAppDispatch } from '../Store/store';
import { changeAuthData, changeRole } from '../Store/slices/auth';
import { ROLE } from '../types';
import Loading from '../Components/Loading';
import config from '../config.json';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { MeDocument } from '../graphql/queries/user.generated';
import { changeCartCount } from '../Store/slices/cart';
import useScrollToTop from '../Components/helperFunctions/scrollToTop';

let getBackendUri = () => {
    return `https://cook-foryou.com/graphql`;
};

const cache = new InMemoryCache({
    addTypename: false,
});

const writequery = (graphQLErrors: any, networkError: any) => {
    return cache.writeQuery({
        query: gql`
        query error {
          graphQLErrors @client
          networkError @client
          hasError @client
        }
      `,
        data: {
            graphQLErrors: graphQLErrors || null,
            networkError: networkError || null,
            hasError: true,
        },
    });
};

const retryLink = new RetryLink({
    attempts: {
        max: 1,
        retryIf(error) {
            return !!error;
        },
        // retryIf: (error, count, operation) => {
        //     return !!error;
        // },
    },
    delay: {
        initial: 500,
        max: Infinity,
        jitter: true,
    },
});

const graphQLErrors = onError(
    ({ graphQLErrors, networkError, forward, response, operation }) => {
        console.log(graphQLErrors);
        console.log(networkError);
        if (networkError) {
            writequery(null, networkError);
        }
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                // switch (err?.extensions?.code) {
                //     case "INSTANCE_CODE_MISSING":
                //     case "INVALID_INSTANCE_CODE":
                //         // UserLogout(USER_LOGOUT);
                //         break;
                //     case "ACCOUNT_DISABLED":
                //     case "USER_DISABLED":
                //         // ACCOUNT_DISABLED(err);
                //         return;
                //     case "SUBSCRIPTION_EXPIRED":
                //     case "SUBSCRIPTION_EXPIRED_RENEW":
                //         // SUBSCRIPTION_EXPIRED(err);
                //         break;
                // }

                if (err?.extensions?.category === "authentication") {
                    // AUTHENTICATION(err);
                    return;
                }
            }
            writequery(graphQLErrors, null);
        }
    }
);

let authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem("token");
    const Languages = config.app.languages;
    const Language = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : Languages[0];
    // const instanceCode = localStorage.getItem("instanceCode");
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : "",
            "Content-Language": Language,
            "X-Client-Type": "WEB",
            // "X-Client-Name": osName + "-" + browserName,
            // "X-App-Version": pjson.version,
            // ...(multiInstance && { "X-Instance-Code": instanceCode }),

            ...operation.getContext().headers,
        },
    });
    return forward(operation);
});

const uploadLink = createUploadLink({ uri: getBackendUri });

export const client = new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, retryLink, graphQLErrors, uploadLink]),

});

const ApolloProviderComponent = ({
    children
}: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true)
    const [networkError, setNetworkError] = useState(false)
    useScrollToTop();
    useEffect(() => {
        client
            .query({
                query: gql`${CATEGORIES.query}`,
                fetchPolicy: "no-cache"
            })
            .then((data) => {
                if (!localStorage.getItem("token"))
                    setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                setNetworkError(true)
            });
        if (localStorage.getItem("token")) {
            client
                .query({
                    query: gql`${MeDocument}`,
                    fetchPolicy: "no-cache"
                })
                .then((data) => {
                    setLoading(false)
                    if (data.data.me.isAdmin) {
                        dispatch(changeRole(ROLE.ADMIN))
                    } else {
                        dispatch(changeAuthData({
                            role: ROLE.CUSTOMER,
                            user: {
                                birthdate: data.data?.me.birthdate,
                                email: data.data?.me.email!,
                                name: data.data?.me.name!
                            },
                            addresses: data.data.me.addresses
                        }))

                        if (data.data.me.cart && data.data.me.cart.items.length > 0) {
                            dispatch(changeCartCount(data.data.me.cart.items.length))
                        }
                    }
                })
                .catch(({ graphQLErrors }) => {
                    if (graphQLErrors?.[0]?.message === "Unauthenticated.") {
                        localStorage.removeItem("token")
                        dispatch(changeAuthData({
                            role: "",
                            isLoggedIn: false
                        }))
                        setLoading(false)
                    } else {
                        setLoading(false)
                        // setNetworkError(true)
                    }

                });
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ApolloProvider client={client}>
            {loading ? <Loading /> : (networkError ? <NetworkError /> : children)}
        </ApolloProvider>
    )
}

export default memo(ApolloProviderComponent)

const NetworkError: React.FC = () => {
    const handleReload = () => {
        window.location.reload();
    };
    return (
        <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"}>
            <Paper
                elevation={3}
                sx={{
                    maxWidth: 400,
                    padding: 4,
                    textAlign: "center",
                }}
            >
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h6" color="error" gutterBottom>
                        Network Error
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Something went wrong. Please check your connection and try again.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Refresh />}
                        onClick={handleReload}
                    >
                        Reload
                    </Button>
                </Stack>
            </Paper>
        </Stack>
    );
};