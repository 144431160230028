import { Stack, Typography } from '@mui/material'
import React from 'react'
import { styled } from "@mui/material/styles";

const PREFIX = "SectionTitle";

const classes = {
    titleDivider: `${PREFIX}-titleDivider`,
    titleDividerDot: `${PREFIX}-titleDividerDot`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.titleDivider}`]: {
        borderRadius: "20px",
        direction: "rtl",
        width: "100px",
        height: 4,
        background: `${theme.palette.secondary.main}`,
    },
    [`& .${classes.titleDividerDot}`]: {
        borderRadius: "20px",
        direction: "rtl",
        width: "10px",
        height: 4,
        background: `${theme.palette.primary.main}`,
    }
}));

interface inputProps {
    sectionTitle: string
    subSectionTitle?: string
}

const SectionTitle = (props: inputProps) => {
    const { sectionTitle, subSectionTitle } = props
    return (
        <Root pb={3} alignItems={"center"} spacing={2}>
            <Typography variant='h2' textAlign={"center"} textTransform={"capitalize"} color={"primary.main"}>
                {sectionTitle}
            </Typography>
            {subSectionTitle && <Typography variant='body2' fontSize={14} textAlign={"center"} color={"text.secondary"} mt={1} width={"80%"} mx={"auto"}>
                {subSectionTitle}
            </Typography>}
            <Stack direction={"row"} spacing={.5}>
                <Stack className={classes.titleDividerDot} />
                <Stack className={classes.titleDivider} />
                <Stack className={classes.titleDividerDot} />
            </Stack>

        </Root>
    )
}

export default SectionTitle