import { Box, Container, Grid2 as Grid, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import BreadCrumb from '../../../Components/BreadCrumb';
import notRestaurant from '../../../assets/images/not-restaurant.svg';
import { getBackendUri } from '../../../Components/helperFunctions/getBackendUri';
import { useTranslation } from 'react-i18next';
import LocationPicker from '../../../Components/LocationPicker';
import { useBranchQuery } from '../../../graphql/queries/branches.generated';
import { useParams } from 'react-router-dom';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';

const PREFIX = "Login";

const classes = {
    link: `${PREFIX}-link`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.link}`]: {
        textDecoration: "none",
        color: theme.palette.text.primary
    },
}));

const ViewBranch = () => {
    const { t } = useTranslation()
    const { id } = useParams()

    const { data, loading } = useBranchQuery({
        fetchPolicy: "no-cache",
        skip: !parseInt(id!),
        variables: {
            id: parseInt(id!)
        }
    });

    return (
        <Root>
            <BreadCrumb prevLink={[{ link: "branches", name: "branches" }]} pageLink={data?.branch.name!} />
            <Container maxWidth={"lg"}>
                <Grid container spacing={5} m={0} my={3}>
                    <Grid size={{ md: 6, xs: 12 }}>
                        <Stack height={300} component={Paper} elevation={4}>
                            {loading ?
                                <Skeleton variant="rounded" animation="wave" width={"100%"} height={"100%"} />
                                :
                                <img
                                    src={data?.branch.image?.path ? getBackendUri(data?.branch.image?.path) : notRestaurant}
                                    alt={data?.branch.name}
                                    width={"100%"}
                                    height={"100%"}
                                    style={{ objectFit: "fill", borderRadius: "8px" }}
                                />
                            }
                        </Stack>
                    </Grid>
                    <Grid size={{ md: 6, xs: 12 }}>
                        <Stack spacing={3}>
                            <Stack>
                                <Typography color="primary.main">
                                    {t("name")}:
                                </Typography>
                                <Typography variant="h1" fontSize={30}>
                                    {loading ? <Skeleton animation="wave" width={"80px"} /> : data?.branch.name}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography color="primary.main">
                                    {t("address")}:
                                </Typography>

                                {loading ?
                                    <Stack >
                                        <Skeleton animation="wave" width={"200px"} />
                                    </Stack>
                                    :
                                    <Typography>{data?.branch.address}</Typography>
                                }
                            </Stack>
                            <Stack spacing={0.5}>
                                <Typography color="primary.main">
                                    {t("contacts")}:
                                </Typography>

                                {loading ?
                                    <Stack >
                                        <Skeleton animation="wave" width={"200px"} />
                                    </Stack>
                                    :
                                    <Stack spacing={1} alignItems={"flex-start"}>
                                        <a
                                            href={`https://wa.me/${data?.branch.whatsapp}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            title="whatsApp"
                                            className={classes.link}
                                        >
                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                <FaWhatsapp color='#25D366' size={25} />
                                                <Typography>{data?.branch.whatsapp}</Typography>
                                            </Stack>
                                        </a>
                                        <a
                                            href={`tel:${data?.branch.telephone}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            title="telephone"
                                            className={classes.link}
                                        >
                                            <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                                                <FaPhoneAlt color='#0088cc' size={20} />
                                                <Typography>{data?.branch.telephone}</Typography>
                                            </Stack>
                                        </a>
                                    </Stack>
                                }
                            </Stack>
                            <Stack spacing={0.5}>
                                <Typography color="primary.main">
                                    {t("location")}:
                                </Typography>
                                {loading ?
                                    <Skeleton animation="wave" variant='rounded' width={"100%"} height={"300px"} />
                                    :
                                    <LocationPicker
                                        height={"300px"}
                                        view
                                        location={data?.branch.latitude ? [data?.branch.latitude, data?.branch.longitude] : [0, 0]}
                                    />
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Box width={"100%"}>
                            <Grid container spacing={2} justifyContent={"center"}>
                                {data?.branch.tables.map(e =>
                                    <Grid size={{ md: 3, sm: 4, xs: 6 }} key={e?.id}>
                                        <Stack component={Paper} p={2}>
                                            <Typography>
                                                {e?.name}
                                            </Typography>
                                            <Typography>
                                                {t("chairsNumber")}: {e?.chairsNumber}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    )
}

export default ViewBranch