import { Avatar, Stack, Typography } from "@mui/material";
import { useCartQuery } from "../../../graphql/queries/user.generated";
import { getBackendUri } from "../../../Components/helperFunctions/getBackendUri";
import { formatted } from "../../../Components/helperFunctions/function";
import { t } from "i18next";

const CartData = () => {
    const { data } = useCartQuery({
        fetchPolicy: "no-cache"
    });

    return (
        <Stack spacing={2} p={2}>
            {data?.cart.items.map(e =>
                <Stack direction={"row"} spacing={1} key={e?.id}>
                    <Avatar>
                        <img
                            src={e?.product.image?.path ? getBackendUri(e?.product.image?.path) : ""}
                            alt={e?.product.name}
                            width={"100%"}
                            height={"100%"}
                            style={{ objectFit: "fill" }}
                        />
                    </Avatar>
                    <Stack>
                        <Typography>{e?.product.name}</Typography>
                        <Typography>{t("quantity")}: {e?.quantity}</Typography>
                        <Typography>{formatted(e?.price)}</Typography>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}

export default CartData