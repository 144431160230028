import { Avatar, Icon, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LabelValue = ({
    label,
    value,
    icon
}: { label: string, value: string, icon?: string }) => {
    const { t } = useTranslation()
    return (
        <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Avatar sx={{ width: "30px", height: "30px", bgcolor: "#fff" }}>
                <Icon color='primary'>{icon}</Icon>
            </Avatar>
            <Stack>
                <Typography>{label}</Typography>
                <Typography>{value ?? t("placeholder")}</Typography>
            </Stack>
        </Stack>
    )
}

export default LabelValue