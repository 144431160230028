import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "Banner";
const classes = {
    stickyHeader: `${PREFIX}-stickyHeader`,
};

const Root = styled(Stack)(({ theme }) => ({
    [`& .${classes.stickyHeader}`]: {

    },
}));


const Banner = () => {
    return (
        <Root py={4} spacing={3} alignItems={"center"}>
            <Typography variant="h1" color="primary.main">For You</Typography>
            <Typography textAlign={"center"} width={"80%"} fontSize={20} color="text.secondary">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, dolore sed? Tenetur possimus quasi dolorem mollitia tempora rem pariatur quis cupiditate, accusamus dignissimos perspiciatis saepe. Accusamus cupiditate explicabo quis nisi!
            </Typography>
        </Root>
    )
}

export default Banner