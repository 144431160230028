import { IconButton, Tooltip } from '@mui/material'
import { LoginOutlined, LogoutOutlined } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../Store/store'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import LogoutDialog from '../LogoutDialog'

const LoginIcon = () => {
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const location = useLocation()
    const [logoutState, setLogoutState] = useState<boolean>(false)

    const { t } = useTranslation()
    const handleCloseLogoutDialog = () => {
        setLogoutState(false)
    }

    const handleOpenLogoutDialog = () => {
        setLogoutState(true)
    }

    return ["/login", "/register"].includes(location.pathname) ? null : isLoggedIn ? (
        <>
            {logoutState && <LogoutDialog open={logoutState} handleClose={handleCloseLogoutDialog} />}
            <Tooltip title={t("logout")}>
                <IconButton
                    aria-label="profile"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="default"
                    onClick={handleOpenLogoutDialog}
                >
                    <LogoutOutlined />
                </IconButton>
            </Tooltip>
        </>

    ) : (
        <Tooltip title={t("login")}>
            <Link to={"/login"}>
                <IconButton
                    aria-label="profile"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="default"
                >
                    <LoginOutlined />
                </IconButton>
            </Link>
        </Tooltip>
    )
}

export default LoginIcon