import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Store/store';
import { ROLE } from '../../types';
import { Link } from 'react-router-dom';
import { Dashboard } from '@mui/icons-material';

const DashboardComp = () => {
    const { role } = useAppSelector((state) => state.auth);
    const { t } = useTranslation()

    if (role !== ROLE.ADMIN) {
        return null;
    }

    return (
        <Tooltip title={t("dashboard")}>
            <Link to={"/dashboard"}>
                <IconButton
                    aria-label="dashboard"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="default"
                >
                    <Dashboard />
                </IconButton>
            </Link>
        </Tooltip>
    )
}

export default DashboardComp