import React from 'react'
import Details from './Component/Details'

const Dashboard = () => {
  return (
    <div>
      <Details />
    </div>
  )
}

export default Dashboard