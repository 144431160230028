import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Save_CategoryMutationVariables = Types.Exact<{
  input: Types.CategoryInput;
}>;


export type Save_CategoryMutation = { __typename?: 'Mutation', saveCategory: { __typename?: 'Category', id: number, name: string } };

export type Save_ProductMutationVariables = Types.Exact<{
  input: Types.ProductInput;
}>;


export type Save_ProductMutation = { __typename?: 'Mutation', saveProduct: { __typename?: 'Product', id: number } };


export const Save_CategoryDocument = gql`
    mutation SAVE_CATEGORY($input: CategoryInput!) {
  saveCategory(input: $input) {
    id
    name
  }
}
    `;
export type Save_CategoryMutationFn = Apollo.MutationFunction<Save_CategoryMutation, Save_CategoryMutationVariables>;

/**
 * __useSave_CategoryMutation__
 *
 * To run a mutation, you first call `useSave_CategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_CategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCategoryMutation, { data, loading, error }] = useSave_CategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_CategoryMutation(baseOptions?: Apollo.MutationHookOptions<Save_CategoryMutation, Save_CategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_CategoryMutation, Save_CategoryMutationVariables>(Save_CategoryDocument, options);
      }
export type Save_CategoryMutationHookResult = ReturnType<typeof useSave_CategoryMutation>;
export type Save_CategoryMutationResult = Apollo.MutationResult<Save_CategoryMutation>;
export type Save_CategoryMutationOptions = Apollo.BaseMutationOptions<Save_CategoryMutation, Save_CategoryMutationVariables>;
export const Save_ProductDocument = gql`
    mutation SAVE_PRODUCT($input: ProductInput!) {
  saveProduct(input: $input) {
    id
  }
}
    `;
export type Save_ProductMutationFn = Apollo.MutationFunction<Save_ProductMutation, Save_ProductMutationVariables>;

/**
 * __useSave_ProductMutation__
 *
 * To run a mutation, you first call `useSave_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProductMutation, { data, loading, error }] = useSave_ProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_ProductMutation(baseOptions?: Apollo.MutationHookOptions<Save_ProductMutation, Save_ProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_ProductMutation, Save_ProductMutationVariables>(Save_ProductDocument, options);
      }
export type Save_ProductMutationHookResult = ReturnType<typeof useSave_ProductMutation>;
export type Save_ProductMutationResult = Apollo.MutationResult<Save_ProductMutation>;
export type Save_ProductMutationOptions = Apollo.BaseMutationOptions<Save_ProductMutation, Save_ProductMutationVariables>;