import React, { useState } from "react";
import {
    FormControl,
    FormHelperText,
    Stack,
    TextField,
} from "@mui/material";
import { useController, Control } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { styled } from "@mui/material/styles";

interface MUIDateProps {
    control: Control<any>;
    disabled?: boolean;
    name: string;
    label?: string;
    rules?: Record<string, any>;
    defaultValue?: Date | null;
    variant?: "filled" | "outlined" | "standard";
    onChange?: (value: Date | null) => void;
    value?: Date | null;
    InputProps?: Record<string, any>;
    [key: string]: any;
}

const Root = styled(Stack)(({ theme }) => ({
    [`& .MuiInputAdornment-positionEnd`]: {
        height: "auto",
        maxHeight: "none",
        [`& button`]: {
            margin: "0 !important",
            padding: theme.spacing(0.6),
        },
    },
}));

const MUIDate: React.FC<MUIDateProps> = ({
    control,
    disabled,
    name,
    label,
    rules,
    defaultValue,
    variant,
    onChange,
    value,
    InputProps,
    ...restProps
}) => {
    const {
        formState: { errors },
        field: { ref, value: fieldValue, onChange: fieldChange, ...fieldProps },
    } = useController({
        name,
        control,
        rules: { ...rules },
        defaultValue: defaultValue ?? null,
    });

    const [dateRangeState, setDateRangeState] = useState(false);
    const handleOpenDateRange = () => setDateRangeState(true);
    const handleCloseDateRange = () => setDateRangeState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} >
            <FormControl variant="filled" fullWidth size="small">
                <DatePicker
                    {...restProps}
                    label={label}
                    value={value || fieldValue}
                    format="dd/MM/yyyy" // Adjusted to `format` in v6
                    onChange={(newValue) => {
                        fieldChange(newValue);
                        onChange && onChange(newValue);
                    }}
                    open={dateRangeState}
                    onClose={handleCloseDateRange}
                    onOpen={handleOpenDateRange}
                    disabled={disabled}
                    slots={{
                        textField: (params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant={variant ?? "outlined"}
                                helperText={null}
                                onClick={handleOpenDateRange}
                                InputProps={{
                                    ...params.InputProps,
                                    ...(params?.InputProps?.endAdornment && {
                                        endAdornment: (
                                            <Root direction={"row"} spacing={0.5}>
                                                {params?.InputProps?.endAdornment}
                                                {InputProps?.endAdornment}
                                            </Root>
                                        ),
                                    }),
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                }}
                                {...fieldProps}
                            />
                        ),
                    }}
                />
                {errors[name] && (
                    <FormHelperText error>
                        {errors[name]?.message as string}
                    </FormHelperText>
                )}
            </FormControl>
        </LocalizationProvider>
    );
};

export default MUIDate;
