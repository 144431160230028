import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { styled } from "@mui/material/styles";
import { Container, Paper, Stack, Tab, Tabs } from "@mui/material";
import image from "../../assets/images/sandwich.jpg"
import { Info, PersonPin, Toc } from "@mui/icons-material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

const PREFIX = "ProfileLayout";

const classes = {
    background: `${PREFIX}-background`,
    imageWrapper: `${PREFIX}-imageWrapper`,
    image: `${PREFIX}-image`,
    tabs: `${PREFIX}-tabs`,
};

const Root = styled("div")(({ theme }) => ({
    margin: theme.spacing(4, 0),
    [`& .${classes.background}`]: {
        background: theme.palette.primary.main,
        height: "120px"
    },
    [`& .${classes.imageWrapper}`]: {
        border: "5px solid #fff",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        margin: "auto",
        overflow: "hidden",
        marginTop: "-80px"
    },
    [`& .${classes.image}`]: {
        objectFit: "fill"
    },
    [`& .${classes.tabs}`]: {
        [`& .MuiTabs-flexContainer`]: {
            justifyContent: "center"
        },
    },
}));

const TABS_NAME = ["profile", "addresses", "orders"]

const ProfileLayout = () => {
    const location = useLocation();

    // Split the path and get the second segment
    const secondSegment = location.pathname.split('/')[2];

    const [value, setValue] = useState(secondSegment ? TABS_NAME.indexOf(secondSegment) : 0);

    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(newValue === 0 ? `/${TABS_NAME[newValue]}` : `/profile/${TABS_NAME[newValue]}`)
    };

    return (
        <Root>
            <Container maxWidth="lg">
                <Paper className={classes.background} elevation={5} />
                <Paper className={classes.imageWrapper} elevation={5}>
                    <img src={image} alt="aaa" width={"100%"} height={"100%"} className={classes.image} />
                </Paper>

                <Stack mt={2}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="profile details"
                        className={classes.tabs}
                    >
                        <Tab icon={<Info />} label={t("details")} />
                        <Tab icon={<PersonPin />} label={t("addresses")} />
                        <Tab icon={<Toc />} label={t("orders")} />
                    </Tabs>
                    <Outlet />
                </Stack>
            </Container>
        </Root>
    )
}

export default memo(ProfileLayout)