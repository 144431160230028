import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Root = styled("span")(({ theme }) => ({
    // background: theme.palette.background.default,
    "a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        alignItems: "center",
        "&:hover": {
            textDecoration: "underline",
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
    }
}));


export const SpanLink = ({ to, exact, children }: any) => {
    return (
        <Root>
            <Link to={to}>
                {children}
            </Link>
        </Root>
    )
}