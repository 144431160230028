import { Button, ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from "@mui/material/styles";

const PREFIX = "ButtonLink";

const classes = {
    fullWidth: `${PREFIX}-fullWidth`,
};

const Root = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    [`&.${classes.fullWidth}`]: {
        display: "block",
        width: "100%"
    },
}));

interface ButtonLinkProps extends ButtonProps {
    to: string;
    text: string;
}

const ButtonLink = ({ to, text, ...buttonProps }: ButtonLinkProps) => {    
    return (
        <Root to={to} className={buttonProps.fullWidth ? classes.fullWidth : ""}>
            <Button variant='contained' color='primary' {...buttonProps}>
                {text}
            </Button>
        </Root>
    );
};

export default ButtonLink;
