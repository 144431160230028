import React from 'react'
import { styled } from "@mui/material/styles";
import { Avatar, Container, Paper, Stack, Typography } from '@mui/material';
import { LockOpen } from '@mui/icons-material';
import ControlMUITextField from '../../Components/MUI/ControlMUItextField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SpanLink } from '../../Components/SpanLink';
import { LoadingButton } from '@mui/lab';
import MUIDate from '../../Components/MUI/MuiDate';
import { useLoginMutation, useRegisterMutation } from '../../graphql/mutations/auth.generated';
import { RegisterInput } from '../../graphql/types';
import { toast } from 'sonner';
import useAfterLogin, { DataTypes } from './component/useAfterLogin';
import moment from 'moment';

const PREFIX = "Register";

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
};

const Root = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(10, 0),
  [`& .${classes.paper}`]: {
    width: "100%",
    padding: theme.spacing(4),
  },
  [`& .${classes.avatar}`]: {
    width: 56,
    height: 56,
    background: theme.palette.primary.main
  },
}));

const dateFormat = (date: string) => moment(date).locale("en").format("YYYY-MM-DD");

const Register = () => {

  const { control, handleSubmit } = useForm<RegisterInput>()
  const { t } = useTranslation()

  const [registerMutation, { loading }] = useRegisterMutation();
  const [loginMutation, { loading: loginLoading }] = useLoginMutation();
  const { callFunction } = useAfterLogin()

  const onSubmit = (data: RegisterInput) => {
    registerMutation({
      variables: {
        input: {
          ...data,
          birthdate: dateFormat(data.birthdate)
        }
      }
    }).then((regData) => {
      toast.success(t("accountHasBeenCreated"))
      if (regData.data?.register) {
        loginMutation({
          variables: {
            input: {
              email: data.email,
              password: data.password
            }
          }
        }).then((data) => {
          const userData: DataTypes = {
            token: data.data?.login.token!,
            user: {
              addresses: data.data?.login.user.addresses as any,
              birthdate: data.data?.login.user.birthdate!,
              email: data.data?.login.user.email!,
              name: data.data?.login.user.name!,
              cartCount: data.data?.login.user.cart?.items.length ?? 0,
              isAdmin: data.data?.login.user.isAdmin!
            }
          }
          callFunction(userData)
        }).catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
        })
      }

    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <Root justifyContent={"center"} alignItems={"center"} height={"100%"}>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            className={classes.paper}
            component={Paper}
            elevation={3}
            spacing={3}
            alignItems={"center"}
          >
            <Avatar className={classes.avatar}>
              <LockOpen fontSize='large' />
            </Avatar>
            <Typography variant='h1' fontSize={40}>{t("signUp")}</Typography>
            <ControlMUITextField
              control={control}
              name='name'
              label={t('name')}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
            <ControlMUITextField
              control={control}
              name='email'
              label={t('email')}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
            {/* <ControlMUITextField
              control={control}
              name='mobile'
              label={t('mobile')}
              rules={{
                required: t("fieldIsRequired"),
              }}
            /> */}
            <ControlMUITextField
              name='password'
              label={t('password')}
              type='password'
              control={control}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
            <MUIDate
              control={control}
              name="birthdate"
              label={t("birthDate")}
            />
            <LoadingButton variant='contained' type='submit' fullWidth loading={loading || loginLoading}>
              {t('signUp')}
            </LoadingButton>
            <Typography>
              {t("haveAccount") + " "}
              <SpanLink to={"/login"}>
                {t("login")}
              </SpanLink>
            </Typography>
          </Stack>
        </form>
      </Container>
    </Root>
  )
}

export default Register