import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Save_BranchMutationVariables = Types.Exact<{
  input: Types.BranchInput;
}>;


export type Save_BranchMutation = { __typename?: 'Mutation', saveBranch: { __typename?: 'Branch', id: number } };

export type Save_TableMutationVariables = Types.Exact<{
  input: Types.TableInput;
}>;


export type Save_TableMutation = { __typename?: 'Mutation', saveTable: { __typename?: 'Table', id: number } };


export const Save_BranchDocument = gql`
    mutation SAVE_BRANCH($input: BranchInput!) {
  saveBranch(input: $input) {
    id
  }
}
    `;
export type Save_BranchMutationFn = Apollo.MutationFunction<Save_BranchMutation, Save_BranchMutationVariables>;

/**
 * __useSave_BranchMutation__
 *
 * To run a mutation, you first call `useSave_BranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_BranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBranchMutation, { data, loading, error }] = useSave_BranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_BranchMutation(baseOptions?: Apollo.MutationHookOptions<Save_BranchMutation, Save_BranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_BranchMutation, Save_BranchMutationVariables>(Save_BranchDocument, options);
      }
export type Save_BranchMutationHookResult = ReturnType<typeof useSave_BranchMutation>;
export type Save_BranchMutationResult = Apollo.MutationResult<Save_BranchMutation>;
export type Save_BranchMutationOptions = Apollo.BaseMutationOptions<Save_BranchMutation, Save_BranchMutationVariables>;
export const Save_TableDocument = gql`
    mutation SAVE_TABLE($input: TableInput!) {
  saveTable(input: $input) {
    id
  }
}
    `;
export type Save_TableMutationFn = Apollo.MutationFunction<Save_TableMutation, Save_TableMutationVariables>;

/**
 * __useSave_TableMutation__
 *
 * To run a mutation, you first call `useSave_TableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_TableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTableMutation, { data, loading, error }] = useSave_TableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_TableMutation(baseOptions?: Apollo.MutationHookOptions<Save_TableMutation, Save_TableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_TableMutation, Save_TableMutationVariables>(Save_TableDocument, options);
      }
export type Save_TableMutationHookResult = ReturnType<typeof useSave_TableMutation>;
export type Save_TableMutationResult = Apollo.MutationResult<Save_TableMutation>;
export type Save_TableMutationOptions = Apollo.BaseMutationOptions<Save_TableMutation, Save_TableMutationVariables>;