import { CircularProgress, Stack } from '@mui/material'

const Loading = ({ height }: { height?: string }) => {
    return (
        <Stack justifyContent={"center"} alignItems={"center"} height={height ?? "100dvh"} width={"100%"}>
            <CircularProgress />
        </Stack>

    )
}

export default Loading