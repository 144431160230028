import { styled } from '@mui/material/styles';
import { Container, Grid2 as Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Inventory2Outlined, InventoryOutlined, PeopleOutlineOutlined } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

const PREFIX = "Details";

const classes = {
    link: `${PREFIX}-link`,
};

// const drawerWidth = 240;

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Container)(({ theme }) => ({
    [`& .${classes.link}`]: {
        textDecoration: "none",
    },
}));

const Details = () => {
    const { t } = useTranslation(["dashboard"])
    // const { data: ordersData, loading: loadingOrders } = useOrdersQuery({
    //     fetchPolicy: "network-only",
    //     notifyOnNetworkStatusChange: true,
    //     variables: {
    //         page: 1,
    //         limit: 100,
    //     },
    // });

    // const { data: customersData, loading: loadingCustomers } = useCustomersQuery({
    //     fetchPolicy: "network-only",
    //     notifyOnNetworkStatusChange: true,
    //     variables: {
    //         page: 1,
    //         limit: 100,
    //     },
    // });

    // const { data: productsData, loading: loadingProducts } = useProductsQuery({
    //     fetchPolicy: "network-only",
    //     notifyOnNetworkStatusChange: true,
    //     variables: {
    //         page: 1,
    //         limit: 100,
    //     },
    // })

    return (
        <Root maxWidth={"xl"}>
            <Grid container spacing={2} width={"100%"} m={0}>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Paper sx={{ p: 4, width: "100%" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"} textTransform={"capitalize"}>{t("orders")}</Typography>
                                <Typography variant={"body2"} fontSize={18}>
                                    {false ? <Skeleton sx={{ m: 0 }} animation="wave" width={"80%"} /> : 10}
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}>
                                <InventoryOutlined fontSize='large' sx={{ color: "white" }} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Paper sx={{ p: 4, width: "100%" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"} textTransform={"capitalize"}>{t("products")}</Typography>
                                <Typography variant={"body2"} fontSize={18}>
                                    {false ? <Skeleton animation="wave" width={"80%"} /> : 10}
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}>
                                <Inventory2Outlined fontSize='large' sx={{ color: "white" }} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Paper sx={{ p: 4, width: "100%" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"} textTransform={"capitalize"}>{t("customers")}</Typography>
                                <Typography variant={"body2"} fontSize={18}>
                                    {false ? <Skeleton animation="wave" width={"80%"} /> : 10}
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}>
                                <PeopleOutlineOutlined fontSize='large' sx={{ color: "white" }} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Details
