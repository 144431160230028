import { styled } from "@mui/material/styles";
import { Avatar, Container, Paper, Stack, Typography } from '@mui/material';
import { LockOpen } from '@mui/icons-material';
import ControlMUITextField from '../../Components/MUI/ControlMUItextField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SpanLink } from '../../Components/SpanLink';
import { LoadingButton } from '@mui/lab';
import { LoginInput } from "../../graphql/types";
import { useLoginMutation } from "../../graphql/mutations/auth.generated";
import { setValidationError } from "../../Components/helperFunctions/setValidationError";
import { toast } from "sonner";
import useAfterLogin, { DataTypes } from "./component/useAfterLogin";

const PREFIX = "Login";

const classes = {
    paper: `${PREFIX}-paper`,
    avatar: `${PREFIX}-avatar`,
};

const Root = styled(Stack)(({ theme }) => ({
    margin: theme.spacing(10, 0),

    [`& .${classes.paper}`]: {
        width: "100%",
        padding: theme.spacing(4),
    },
    [`& .${classes.avatar}`]: {
        width: 56,
        height: 56,
        background: theme.palette.primary.main
    },
}));

const Login = () => {
    const { control, handleSubmit, setError } = useForm<LoginInput>()
    const { t } = useTranslation()

    const { callFunction } = useAfterLogin()
    const [loginMutation, { loading }] = useLoginMutation();

    const onSubmit = (data: LoginInput) => {
        loginMutation({
            variables: {
                input: {
                    ...data
                }
            }
        }).then((data) => {
            const userData: DataTypes = {
                token: data.data?.login.token!,
                user: {
                    addresses: data.data?.login.user.addresses as any,
                    birthdate: data.data?.login.user.birthdate!,
                    email: data.data?.login.user.email!,
                    name: data.data?.login.user.name!,
                    cartCount: data.data?.login.user.cart?.items.length ?? 0,
                    isAdmin: data.data?.login.user.isAdmin!
                }
            }
            callFunction(userData)
        }).catch(({ graphQLErrors }) => {
            console.log(graphQLErrors);
            const errorCode = graphQLErrors[0]?.extensions?.code;
            if (errorCode === "EMAIL_NOT_VERIFIED") {
                // setDialog(true);
                // setValidationError(graphQLErrors, setError);
            } else if (errorCode === "ACCOUNT_NOT_VERIFIED") {
                // setDialog(true);
                // setHoldVerifay(true);
            } else if (
                errorCode === "INVALID_CREDENTIALS"
                // errorCode === "ACCOUNT_DISABLED" ||
                // errorCode === "USER_DISABLED"
            ) {
                toast.error(graphQLErrors[0]?.message)
                // enqueueSnackbarFunc(graphQLErrors[0]?.message, "error");
            }
            else if (graphQLErrors[0]?.extensions?.category === "validation") {
                setValidationError({
                    setError: setError,
                    graphQLErrors: graphQLErrors,
                })
            }
        })
    }

    return (
        <Root justifyContent={"center"} alignItems={"center"} height={"100%"}>
            <Container maxWidth="sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack
                        className={classes.paper}
                        component={Paper}
                        elevation={3}
                        spacing={3}
                        alignItems={"center"}
                    >
                        <Avatar className={classes.avatar}>
                            <LockOpen fontSize='large' />
                        </Avatar>
                        <Typography variant='h1' fontSize={40}>{t("login")}</Typography>
                        <ControlMUITextField
                            control={control}
                            name='email'
                            label={t('email')}
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                        />
                        <ControlMUITextField
                            name='password'
                            label={t('password')}
                            type='password'
                            control={control}
                            rules={{
                                required: t("fieldIsRequired"),
                            }}
                        />
                        <LoadingButton variant='contained' type='submit' fullWidth loading={loading}>
                            {t('login')}
                        </LoadingButton>
                        <Typography>
                            {t("notHaveAccount") + " "}
                            <SpanLink to={"/register"}>
                                {t("signUp")}
                            </SpanLink>
                        </Typography>
                    </Stack>
                </form>
            </Container>
        </Root>
    )
}

export default Login