import { useEffect, useState } from "react";
import { Control, useController, FieldValues, Path } from "react-hook-form";
import { FormHelperText, FormControl } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Define the props interface for MuiTimePicker
interface MuiTimePickerProps<T extends FieldValues> {
    control: Control<T>; // React Hook Form control
    name: Path<T>; // Name of the field in the form
    label: string; // Label for the TimePicker
    onChange?: (value: Date | null) => void; // Optional onChange callback
    defaultValue?: any; // Allow other props to pass through
    [key: string]: any; // Allow other props to pass through
}

const MuiTimePicker = <T extends FieldValues>({
    control,
    name,
    label,
    onChange,
    defaultValue = null, // Accept defaultValue as a prop
    ...restProps
}: MuiTimePickerProps<T> & { defaultValue?: Date | null }): JSX.Element => {
    const {
        field: { onChange: fieldOnChange, value },
        formState: { errors },
    } = useController({
        name,
        control,
        defaultValue, // Set the default value in the useController hook
    });

    const [selectedTime, setSelectedTime] = useState<Date | null>(defaultValue ?? value);

    useEffect(() => {
        setSelectedTime(value);
    }, [value]);

    const errorName = name.includes(".") ? name.split(".") : null;
    const fieldError = errorName
        ? (errors?.[errorName[0]] as Record<string, any>)?.[errorName[1]]
        : errors?.[name as keyof typeof errors];

    return (
        <FormControl component="fieldset" variant="standard" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label={label}
                    value={selectedTime}
                    ampm={false} // Disable AM/PM format for 24-hour mode
                    onChange={(newValue) => {
                        setSelectedTime(newValue);
                        fieldOnChange(newValue); // Update the form value
                        if (onChange) {
                            onChange(newValue);
                        }
                    }}
                    {...restProps}
                    slotProps={{
                        textField: {
                            error: !!fieldError,
                            helperText: fieldError?.message,
                            fullWidth: true,
                        },
                    }}
                />
            </LocalizationProvider>
            {fieldError && (
                <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
                    {fieldError?.message}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default MuiTimePicker;
