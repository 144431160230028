import { styled } from "@mui/material/styles";
import { Container, Divider, Paper, Stack, Typography } from '@mui/material';

import daily from "../assets/images/daily.png"
import Tracing from "../assets/images/delivery.png"
import Quick from "../assets/images/quick.png"
import { useTranslation } from 'react-i18next';
import useWidth, { isWidthDown } from "../Components/helperFunctions/useWidth";

const PREFIX = "products-created";
const classes = {
    container: `${PREFIX}-container`,
};

const Root = styled(Stack)(({ theme }) => ({
    backgroundImage: `radial-gradient(#fadebc, #fadebc)`,
    [`& .${classes.container}`]: {
        display: "flex",
        justifyContent: "center"
    }
}));

const Services = () => {
    const { t } = useTranslation()

    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("sm", screenWidth);



    return (
        <Root py={8}>
            <Container maxWidth="md" className={classes.container}>
                <Stack
                    component={Paper}
                    elevation={7}
                    direction={{ md: "row" }}
                    width={{ xs: "250px", md: "100%" }}
                    py={3}
                    borderRadius={"50px"}
                    justifyContent={"space-around"}
                    rowGap={3}
                >
                    <Stack alignItems={"center"} gap={2} >
                        <img src={daily} alt="daily" width={50} height={50} />
                        <Stack>
                            <Typography color="primary" fontWeight={"bolder"} fontSize={18}>{t("dailyDiscounts")}</Typography>
                        </Stack>
                    </Stack>
                    <Divider flexItem orientation={isScreenSmall ? "horizontal" : "vertical"} />
                    <Stack alignItems={"center"} gap={2}>
                        <img src={Tracing} alt="daily" width={50} height={50} />
                        <Stack>
                            <Typography color="primary" fontWeight={"bolder"} fontSize={18}>{t("liveTracing")}</Typography>
                        </Stack>
                    </Stack>
                    <Divider flexItem orientation={isScreenSmall ? "horizontal" : "vertical"} />
                    <Stack alignItems={"center"} gap={2}>
                        <img src={Quick} alt="daily" width={50} height={50} />
                        <Stack>
                            <Typography color="primary" fontWeight={"bolder"} fontSize={18}>{t("quickDelivery")}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Root>
    )
}

export default Services