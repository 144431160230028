import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Save_Cart_ItemMutationVariables = Types.Exact<{
  input: Types.CartItemInput;
}>;


export type Save_Cart_ItemMutation = { __typename?: 'Mutation', saveCartItem: { __typename?: 'CartItem', id: number, price: number, quantity: number, product: { __typename?: 'Product', name: string, price: number, weight?: number | null, id: number, image?: { __typename?: 'Image', path: string } | null }, options: Array<{ __typename?: 'CartItemOption', id: number, value: boolean } | null>, addons: Array<{ __typename?: 'CartItemAddon', id: number, addon: { __typename?: 'Addon', price: number } } | null> } };

export type DeleteCartItemMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteCartItemMutation = { __typename?: 'Mutation', deleteCartItem: boolean };


export const Save_Cart_ItemDocument = gql`
    mutation SAVE_CART_ITEM($input: CartItemInput!) {
  saveCartItem(input: $input) {
    id
    price
    product {
      name
      price
      weight
      id
      image {
        path
      }
    }
    quantity
    price
    options {
      id
      value
    }
    addons {
      id
      addon {
        price
      }
    }
  }
}
    `;
export type Save_Cart_ItemMutationFn = Apollo.MutationFunction<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>;

/**
 * __useSave_Cart_ItemMutation__
 *
 * To run a mutation, you first call `useSave_Cart_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_Cart_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartItemMutation, { data, loading, error }] = useSave_Cart_ItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSave_Cart_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>(Save_Cart_ItemDocument, options);
      }
export type Save_Cart_ItemMutationHookResult = ReturnType<typeof useSave_Cart_ItemMutation>;
export type Save_Cart_ItemMutationResult = Apollo.MutationResult<Save_Cart_ItemMutation>;
export type Save_Cart_ItemMutationOptions = Apollo.BaseMutationOptions<Save_Cart_ItemMutation, Save_Cart_ItemMutationVariables>;
export const DeleteCartItemDocument = gql`
    mutation deleteCartItem($id: Int!) {
  deleteCartItem(id: $id)
}
    `;
export type DeleteCartItemMutationFn = Apollo.MutationFunction<DeleteCartItemMutation, DeleteCartItemMutationVariables>;

/**
 * __useDeleteCartItemMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemMutation, { data, loading, error }] = useDeleteCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCartItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCartItemMutation, DeleteCartItemMutationVariables>(DeleteCartItemDocument, options);
      }
export type DeleteCartItemMutationHookResult = ReturnType<typeof useDeleteCartItemMutation>;
export type DeleteCartItemMutationResult = Apollo.MutationResult<DeleteCartItemMutation>;
export type DeleteCartItemMutationOptions = Apollo.BaseMutationOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>;