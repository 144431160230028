import React from 'react'
import CustomDialog from './MUI/CustomDialog'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLogoutMutation } from '../graphql/mutations/auth.generated'
import { LoadingButton } from '@mui/lab'
import { useAppDispatch } from '../Store/store'
import { resetAuthData } from '../Store/slices/auth'
import CapitalFirstLetter from './CapitalFirstLetter'
import { resetCartData } from '../Store/slices/cart'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'

interface PropsType {
    open: boolean
    handleClose: () => void
}

const LogoutDialog = ({
    open,
    handleClose
}: PropsType) => {

    const { t } = useTranslation()
    const { handleSubmit } = useForm()
    const navigate = useNavigate()
    const [logoutMutation, { loading }] = useLogoutMutation();
    const dispatch = useAppDispatch();

    const onSubmit = () => {
        logoutMutation().then(() => {
            localStorage.removeItem("token")
            dispatch(resetAuthData())
            dispatch(resetCartData())
            navigate("/")
            handleClose()
        }).catch((err) => {
            console.log(err);
            toast.error(t("errorHasBeenOccurred"))
            handleClose()
        })
    }
    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
                noValidate: true
            }}
            content={
                <Stack py={2}>
                    <CapitalFirstLetter textAlign={"center"} text={t("logoutMessage")} />
                </Stack>
            }
            buttonAction={
                <LoadingButton loading={loading} type='submit' variant='contained'>{t("submit")}</LoadingButton>
            }
        />
    )
}

export default LogoutDialog