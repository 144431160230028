import * as React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Drawer, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { ROLE } from '../../types';
import CartContent from './CartContent';
import { changeDialogState } from '../../Store/slices/cart';
import { useLocation } from 'react-router-dom';

const PREFIX = "CartDrawer";
const classes = {
    productWrapper: `${PREFIX}-productWrapper`,
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: theme.palette.getContrastText(theme.palette.primary.main),
        height: "20px",
        width: "20px",
        fontSize: "12px",
        borderRadius: "50%"
    },
}));

const Root = styled(Drawer)(({ theme }) => ({
    [`& .${classes.productWrapper}`]: {
        height: "calc(100% - (200px))",
        overflowY: "scroll",
        MsOverflowStyle: "none", /* Internet Explorer 10+ */
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
}));

export default function CartComponent() {
    const { role } = useAppSelector((state) => state.auth);
    const { value, stateCartDrawer } = useAppSelector((state) => state.cart);
    const { pathname } = useLocation()

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    const toggleDrawer = (newOpen: boolean) => {
        dispatch(changeDialogState(newOpen));
    };


    if (role === ROLE.ADMIN || pathname === "/cart" || pathname === "/checkout") {
        return null
    }

    return (
        <>
            {stateCartDrawer && <Root anchor='right' open={stateCartDrawer} onClose={() => toggleDrawer(false)}>
                <CartContent classes={classes} toggleDrawer={toggleDrawer} />
            </Root>}
            <Tooltip title={t("cart")}>
                <IconButton aria-label="cart" size='medium' onClick={() => toggleDrawer(true)}>
                    <StyledBadge badgeContent={value} color="primary" >
                        <ShoppingCartIcon fontSize='inherit' />
                    </StyledBadge>
                </IconButton>
            </Tooltip>
        </>

    );
}
