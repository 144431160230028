import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../Store/store';

export default function CartChecker({ children }: { children: React.ReactNode }) {
    const { value } = useAppSelector((state) => state.cart);
    
    if (value === 0) {
        return <Navigate to="/" />;
    }

    return children
}