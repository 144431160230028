import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Stack, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ListPaper from '../Layouts/ListPaper';
import { FixedTableCell } from '../Layouts/FixedTableCell';
import MUITablePagination from '../Layouts/TablePagination';
import TableBodyWithLoad from '../Layouts/TableBodyWithLoad';
import { getBackendUri } from '../../../Components/helperFunctions/getBackendUri';
// import CategoriesCreate from './CategoriesCreate';
import { useList_BranchesQuery } from '../../../graphql/queries/branches.generated';
import ButtonLink from '../../../Components/ButtonLink';
import { CellLink } from '../Layouts/CellLink';
import noImage from "../../../assets/images/no-image-svgrepo-com.svg"
import { useList_UsersQuery } from '../../../graphql/queries/user.generated';

const PREFIX = "users";
const classes = {
    filter: `${PREFIX}-filter`,
    filterActive: `${PREFIX}-filterActive`,
    title: `${PREFIX}-title`,
};
const Root = styled("div")(({ theme }) => ({
    height: "100%",
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2),
    },
    [`& .${classes.filter}`]: {
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(1),
        cursor: "pointer",
        [`&:hover`]: {
            background: theme.palette.divider
        },
    },
    [`& .${classes.filterActive}`]: {
        background: theme.palette.divider
    },
    [`& .${classes.title}`]: {
        fontSize: "22px"
    },
}));

export interface DataByIdType {
    id: number
    name: string
    fileName: string | undefined
    active: boolean
    parentId: number
}

export default function UsersList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { t } = useTranslation();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const { data, loading } = useList_UsersQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            first: rowsPerPage,
            page: page
        },
    });

    const tableCellHeader = [
        "name", "email", "birthdate", "createdAt"
    ]

    return (
        <Root>
            {/* {createDialog && <CategoriesCreate open={createDialog} handleClose={handleCloseCreateDialog} dataById={dataById} refetch={refetch} />} */}
            <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
                <Typography className={classes.title}>{t(PREFIX)}</Typography>
                {/* <ButtonLink to='create' variant='contained' text={t("addBranch")} /> */}
            </Stack>
            <ListPaper loading={loading} data={!!(data && data.listUsers.data.length)} restFilter={`${PREFIX}`}>
                <TableContainer sx={{ width: "100%", overflow: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableCellHeader.map(e =>
                                    <TableCell align={'left'} key={e}>
                                        {t(e)}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBodyWithLoad loading={loading} tableCellHeaderLength={tableCellHeader.length}>
                            <TableBody>
                                {data?.listUsers.data.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            <CellLink to={`${row.id}`}>
                                                {row.name}
                                            </CellLink>
                                            <FixedTableCell>
                                                {row.email}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row.birthdate}
                                            </FixedTableCell>
                                            <FixedTableCell align={'left'}>
                                                {row.date}
                                            </FixedTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </TableBodyWithLoad>
                    </Table>
                </TableContainer>
                <MUITablePagination
                    count={data?.listUsers.paginatorInfo.total}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </ListPaper>
        </Root>
    );
}
