import React, { useState } from 'react'
import CustomDialog from '../../../../Components/MUI/CustomDialog'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LocationPicker from '../../../../Components/LocationPicker'

const LocationPickerDialog = ({
    open,
    handleClose,
    setLocationPickedFun,
    location
}: {
    open: boolean
    handleClose: () => void,
    setLocationPickedFun: (location: [number, number]) => void,
    location: [number, number]
}) => {
    const { t } = useTranslation()
    const [locationPicked, setLocationPicked] = useState(location)


    const locationPickedFun = (newLocation: [number, number]) => {
        setLocationPicked(newLocation);
    };

    return (
        <CustomDialog
            open={open}
            handleClose={handleClose}
            title={t("branchLocation")}
            content={
                <Stack >
                    <LocationPicker locationPickedFun={locationPickedFun} location={location} />
                </Stack>
            }
            buttonAction={
                <Button variant='contained' onClick={() => {
                    setLocationPickedFun(locationPicked)
                    handleClose()
                }}>
                    {t("submit")}
                </Button>
            }
        />
    )
}

export default LocationPickerDialog