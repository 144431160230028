import Banner from '../../Sections/Banner'
import Branches from '../../Sections/Branches'
import FixedBackground from '../../Sections/FixedBackground'
import Kitchens from '../../Sections/Kitchens'
import MobileAppDownload from '../../Sections/MobileAppDownload'
import Services from '../../Sections/Services'

const Home = () => {
    return (
        <>
            <Banner />
            <Kitchens />
            <Services />
            <MobileAppDownload />
            <Branches />
            <FixedBackground />
        </>
    )
}

export default Home