import { IconButton, Paper, Stack, Typography } from '@mui/material'
import Imag from '../../../../assets/images/sandwich.jpg'
import TextFieldCustom from '../../../../Components/MUI/TextFieldCustom'
import { useTranslation } from 'react-i18next'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { formatted } from '../../../../Components/helperFunctions/function'
import { getBackendUri } from '../../../../Components/helperFunctions/getBackendUri'
import DeleteButton from './DeleteButton'
import { useSave_Cart_ItemMutation } from '../../../../graphql/mutations/user.generated'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type itemTypes = {
    id: number
    name: string
    price: number
    totalPrice: number
    quantity: number
    image?: string
    productId: number,
    options?: {
        id: number
        optionId: number
        value: boolean
    }[]
    addons?: {
        id: number
        addonId: number
    }[]
}

let increaseTimer: NodeJS.Timeout | null = null;
var decreaseTimer: NodeJS.Timeout | null = null;
var changeTimer: NodeJS.Timeout | null = null;

const CartCard = ({
    item,
    refetch
}: {
    item: itemTypes
    refetch: () => void
}) => {
    const { t } = useTranslation()
    const [value, setValue] = useState<string | number>(item.quantity)
    const [saveCartItemMutation, { loading: saveCartItemLoading }] = useSave_Cart_ItemMutation();


    const decreaseQuantity = (quantity: number) => {
        const currentQuantity = quantity;
        if (currentQuantity === 1) {
            return;
        }
        // Calculate new quantity, ensuring it does not exceed totalQuantity
        const newQuantity = currentQuantity - 1 < 1 ? 1 : currentQuantity - 1;
        // Update the form value

        // Clear the previous timer if it exists
        if (decreaseTimer) {
            clearTimeout(decreaseTimer);
        }
        // Set a new timer to handle the mutation after a delay
        decreaseTimer = setTimeout(async () => {
            saveCartItemMutation({
                variables: {
                    input: {
                        id: item.id,
                        quantity: newQuantity,
                        productId: item.productId,
                        addons: item.addons,
                        options: item.options
                    }
                }
            }).then(() => refetch())
        }, 1000);
    };

    const increaseQuantity = (
        quantity: number,
        totalQuantity: number
    ) => {
        const currentQuantity = quantity;
        if (currentQuantity === totalQuantity) {
            return;
        }
        // Calculate new quantity, ensuring it does not exceed totalQuantity
        const newQuantity =
            currentQuantity + 1 > totalQuantity ? totalQuantity : currentQuantity + 1;
        // Update the form value

        // Clear the previous timer if it exists
        if (increaseTimer) {
            clearTimeout(increaseTimer);
        }
        // Set a new timer to handle the mutation after a delay
        increaseTimer = setTimeout(async () => {
            saveCartItemMutation({
                variables: {
                    input: {
                        id: item.id,
                        quantity: newQuantity,
                        productId: item.productId,
                        addons: item.addons,
                        options: item.options
                    }
                }
            }).then(() => refetch())
        }, 1000);
    };

    const changeQuantity = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        totalQuantity: number
    ) => {
        if (changeTimer) {
            clearTimeout(changeTimer);
        }
        // Set a new timer to handle the mutation after a delay
        changeTimer = setTimeout(async () => {
            const value = event.target.value;

            let newValue = "";
            if (+value < 1 || event.target.value === "") {

                newValue = "1";
                setValue(1);
            } else if (+value > totalQuantity) {
                newValue = `${totalQuantity}`;
                setValue(totalQuantity);
            } else {
                newValue = value;
            }

            saveCartItemMutation({
                variables: {
                    input: {
                        id: item.id,
                        quantity: Number(newValue),
                        productId: item.productId,
                        addons: item.addons,
                        options: item.options
                    }
                }
            }).then(() => refetch())
        }, 1000);
    };

    useEffect(() => {
        setValue(item.quantity)
        return () => { }
    }, [item.quantity])

    return (
        <Stack component={Paper} direction={"row"} justifyContent={"space-between"} p={2}>
            <Stack direction={"row"} spacing={2} flexWrap={"wrap"} useFlexGap>
                <Link to={`/products/${item.productId}/${item.name}`}>
                    <Stack component={Paper} elevation={4} width={120} height={120} sx={{ borderRadius: "20px", overflow: "hidden" }}>
                        <img src={item.image ? getBackendUri(item.image) : Imag} alt="ds" width={"100%"} height={"100%"} style={{ objectFit: "fill" }} />
                    </Stack>
                </Link>
                <Stack spacing={0.5} justifyContent={"space-around"}>
                    <Typography>{item.name}</Typography>
                    <Typography color='primary.main' fontSize={18}>{formatted(item.price)}</Typography>
                    <Stack>
                        <TextFieldCustom
                            disabled={saveCartItemLoading}
                            label={t("quantity")}
                            type='number'
                            // value={item?.quantity}
                            value={value}
                            sx={{ width: '20ch', "& .MuiInputBase-input": { textAlign: "center" } }}
                            onChange={(event) => {
                                setValue(event.target.value)
                                changeQuantity(event, 100)
                            }}
                            slotProps={{
                                input: {
                                    inputProps: {
                                        min: 0,
                                        max: 100,
                                    },
                                    startAdornment:
                                        <IconButton
                                            disabled={item.quantity <= 1 || saveCartItemLoading}
                                            onClick={() => decreaseQuantity(item.quantity)}
                                        >
                                            <FaMinus fontSize={15} />
                                        </IconButton>,
                                    endAdornment:
                                        <IconButton
                                            disabled={saveCartItemLoading || item.quantity === 100}
                                            onClick={() => increaseQuantity(item.quantity, 100)}
                                        >
                                            <FaPlus fontSize={15} />
                                        </IconButton>,
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack alignItems={"flex-end"} justifyContent={"space-between"}>
                <Typography color='error'>{t("totalPrice")} : {formatted(item.totalPrice)}</Typography>
                <DeleteButton id={item.id} refetch={refetch} />
            </Stack>
        </Stack>
    )
}

export default CartCard