import { Box, Container, Grid2 as Grid } from "@mui/material";
import { KitchenCard, KitchenCardLoading } from "../Components/KitchenCard";

import notRestaurant from "../assets/images/not-restaurant.svg";
import SectionTitle from "../Components/SectionTitle";
import { useList_CategoriesQuery } from "../graphql/queries/products.generated";
import { useLocation } from "react-router-dom";
import { getBackendUri } from "../Components/helperFunctions/getBackendUri";
import { useTranslation } from "react-i18next";
import { slugify } from "../Components/helperFunctions/function";



const Kitchens = () => {
    const { data, loading } = useList_CategoriesQuery({
        notifyOnNetworkStatusChange: true,
        variables: {
            input: {
                parentId: null
            },
            first: 100,
            page: 1
        },
    });

    const { pathname } = useLocation()
    const { t } = useTranslation()

    const newPathname = pathname.substring(1); // Removes the first character

    return (
        <Box py={7} bgcolor={(theme) => theme.palette.background.paper}>
            <Container maxWidth={"lg"}>
                <SectionTitle sectionTitle={t("kitchens")} />
                <Grid container spacing={3} justifyContent={"center"}>
                    {
                        loading && [1, 2, 3].map((e) =>
                            <Grid size={{ md: 4, xs: 6 }} key={e}>
                                <KitchenCardLoading />
                            </Grid>
                        )
                    }
                    {data?.listCategories.data.map((i) =>
                        <Grid size={{ md: 4, xs: 6 }} key={i.id}>
                            <KitchenCard
                                title={i.name}
                                imageUrl={i.image?.path ? getBackendUri(i.image?.path) : notRestaurant}
                                to={(i.productsCount === 0 || !i.active) ? "" : `${newPathname ? "" : "kitchens/"}${i.id}/${slugify(i.name)}`}
                                soon={i.productsCount === 0 || !i.active}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    )
}

export default Kitchens